@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.article-component {
  p {
    color: @Gray80;
  }

  p,
  h2 {
    @media screen and (max-width: @screen-md-max) {
      max-width: 48rem;
    }
  }

  .wrapper {
    a {
      @media screen and (max-width: @screen-md-max) {
        max-width: 39rem;
      }

      @media screen and (min-width: @screen-lg-min) {
        max-width: 30rem;
      }
    }
  }
}
